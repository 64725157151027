import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Sidebar from '../components/sideBar';
import {getUsers, suspendUser, hideUser, hideUserFromHomepage} from '../apiService';
import {toast} from "react-toastify";
import {profileUrl} from "../utilService"; // Import API functions

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
`;

const ContentContainer = styled.div`
  flex: 1;
  padding: 20px;

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    border: 1px solid #ddd;
  }

  th, td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }

  th {
    background-color: black;
    color: white;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const SearchBar = styled.input`
  width: 100%; /* Set search bar width to match the table */
  padding: 8px;
  margin-bottom: 20px;
`;

const Button = styled.button`
  cursor: pointer;
  background-color: ${({ suspend }) => (suspend ? '#ff6347' : '#4caf50')};
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  margin-right: 8px; /* Add margin between buttons */
`;


function Dashboard() {
  const [searchTerm, setSearchTerm] = useState([]);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const authToken = localStorage.getItem('authToken');
        const response = await getUsers(authToken);
        setUsers(response.data);
        setFilteredUsers(response.data);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch users. Please try again.');
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleSuspend = async (userId) => {
    try {
      const authToken = localStorage.getItem('authToken');
      await suspendUser(authToken, userId);
      // Update the user's status locally in the table
      setUsers(users.map(user =>
        user._id === userId ? { ...user, suspended: true } : user
      ));
    } catch (error) {
      console.error('Error suspending user:', error);
    }
  };

  const handleHideFromSearch = async (userId) => {
    try {
      const authToken = localStorage.getItem('authToken');
      await hideUser(authToken, userId);
      // Update the user's status locally in the table
      setUsers(users.map(user =>
        user._id === userId ? { ...user, hiddenFromSearch: true } : user
      ));
    } catch (error) {
      console.error('Error hiding user from search:', error);
    }
  };

  const handleHideFromHomepage = async (userId) => {
    try {
      const authToken = localStorage.getItem('authToken');
      const response = await hideUserFromHomepage(authToken, userId);
     // Update the user's status locally in the table
      setUsers(users.map(user =>
          user._id === userId ? { ...user, isHiddenFromHomepage: response.data.data.isHiddenFromHomepage } : user
      ));

      toast.success(`${response.data.data.message}`);
    } catch (error) {
      toast.error(`${error.message}`)
      console.error('Error hiding user from search:', error);
;
    }
  };

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    const filtered = users.filter(user =>
      user.fullName.toLowerCase().includes(searchTerm) ||
      user.username.toLowerCase().includes(searchTerm) ||
      user.email.toLowerCase().includes(searchTerm)
    );
    setFilteredUsers(filtered);
  };

  return (
    <Wrapper>
      <Sidebar />
      <ContentContainer>
        <h2>User Management</h2>
        {loading ? <p>Please wait...</p> : null}
        {error && <p>{error}</p>}
        <SearchBar type="text" placeholder="Search by name, username, or email" onChange={handleSearchChange} />
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Username</th>
              <th>Email</th>
              <th>Stripe Account ID</th>
              <th>Deleted / Suspended</th>
              <th>Creator</th> 
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user._id}>
                {filteredUsers.length <= 20 && user.reelPictures.length > 0 ? <><td><img src={user.reelPictures[0].url} height={50} width={50} /></td></> : <td></td> }
                <td>{user.fullName}</td>
                <td><a target="_blank" href={profileUrl(user.username)}>{user.username}</a></td>
                <td>{user.email}</td>
                <td>{user.stripeAccountId}</td>
                <td>{user.isCreator ? 'Yes' : 'No'}</td>
                <td>{user.isSuspended ? 'Yes' : 'No'}</td> 
                <td>
                  <Button onClick={() => handleSuspend(user._id)} suspend={user.suspended}>
                    {user.suspended ? 'Unsuspend' : 'Suspend'}
                  </Button>
                  <Button onClick={() => handleHideFromSearch(user._id)}>
                    {user.hiddenFromSearch ? 'Unhide from Search' : 'Hide from Search'}
                  </Button>
                  <Button onClick={() => handleHideFromHomepage(user._id)} suspend={!user.isHiddenFromHomepage}>
                    {user.isHiddenFromHomepage ? 'Unhide from Homepage' : 'Hide from Homepage'}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ContentContainer>
    </Wrapper>
  );
}

export default Dashboard;
