import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 200px;
  background: #333; /* Sidebar background color */
  color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const SidebarOption = styled(Link)`
  display: block;
  padding: 10px;
  color: white;
  text-decoration: none;
  transition: background 0.3s;

  &:hover {
    background: #555; /* Hover background color */
  }
`;

const Sidebar = () => {
  const [selectedOption, setSelectedOption] = useState('/creators'); // Initialize with the default selected option

  return (
    <Wrapper>
      <SidebarOption to="/dashboard" className={selectedOption === '/dashboard' ? 'active' : ''} onClick={() => setSelectedOption('/dashboard')}>
        Manage User
      </SidebarOption>
      <SidebarOption to="/creators" className={selectedOption === '/creators' ? 'active' : ''} onClick={() => setSelectedOption('/creators')}>
        Manage Creator
      </SidebarOption>
      <SidebarOption to="/suggested-creators" className={selectedOption === '/suggested-creators' ? 'active' : ''} onClick={() => setSelectedOption('/suggested-creators')}>
            Suggested creators
      </SidebarOption>
      <SidebarOption to="/manage-categories" className={selectedOption === '/manage-categories' ? 'active' : ''} onClick={() => setSelectedOption('/manage-categories')}>
        Manage Categories
      </SidebarOption>
      <SidebarOption to="/manage-subcategories" className={selectedOption === '/manage-subcategories' ? 'active' : ''} onClick={() => setSelectedOption('/manage-subcategories')}>
        Manage Sub Categories
      </SidebarOption>
      <SidebarOption to="/manage-admin" className={selectedOption === '/manage-admin' ? 'active' : ''} onClick={() => setSelectedOption('/manage-admin')}>
        Manage Admin
      </SidebarOption>
      <SidebarOption to="/creators" className={selectedOption === '/creators' ? 'active' : ''} onClick={() => setSelectedOption('/creators')}>
        Creators of the week
      </SidebarOption>
      <SidebarOption to="/manage-request-become-creators" className={selectedOption === '/request-become-creators' ? 'active' : ''} onClick={() => setSelectedOption('/manage-request-become-creators')}>
        Request become creators
      </SidebarOption>
      <SidebarOption to="/manage-request-become-ambassadors" className={selectedOption === '/request-become-ambassadors' ? 'active' : ''} onClick={() => setSelectedOption('/manage-request-become-ambassadors')}>
        Request become ambassadors
      </SidebarOption>
    </Wrapper>
  );
};

export default Sidebar;
