// src/apiService.js
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL;

const apiService = axios.create({
  baseURL: API_BASE_URL,
});

export const login = async (email, password) => {
  try {
    const response = await apiService.post('/admin/login', { email, password });
    return response.data;
  } catch (error) {
    throw error.response?.data || 'An error occurred';
  }
};


export const getCategories = async (authToken) => {
  try {
    const response = await apiService.get('/admin/categories', {
      headers: {
        Authorization: `Bearer ${authToken}`, // Include the token in the Authorization header
      },
    });

    return response.data;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};

export const getSuggestedCreators = async (authToken) => {
  try {
    const response = await apiService.get('/admin/suggested-creators', {
      headers: {
        Authorization: `Bearer ${authToken}`, // Include the token in the Authorization header
      },
    });

    return response.data;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};

export const getRequestBecomeAmbassadors = async (authToken) => {
  try {
    const response = await apiService.get('/admin/request/become-ambassador', {
      headers: {
        Authorization: `Bearer ${authToken}`, // Include the token in the Authorization header
      },
    });
    return response.data;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};

export const getRequestBecomeCreators = async (authToken) => {
  try {
    const response = await apiService.get('/admin/request/become-creator', {
      headers: {
        Authorization: `Bearer ${authToken}`, // Include the token in the Authorization header
      },
    });
    return response.data;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};

export const editCategory = async (authToken, categoryId, newName) => {
  try {
    const response = await apiService.patch(
      `/admin/${categoryId}`,
      { name: newName }, 
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`, 
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};

export const getUsers = async (authToken) => {
  try {
    const response = await apiService.get('/admin/users', {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || 'An error occurred';
  }
};

export const getCreators = async (authToken) => {
  try {
    const response = await apiService.get('/admin/creators', {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || 'An error occurred';
  }
};

export const suspendUser = async (authToken, userId) => {
  try {
    await apiService.patch(`/admin/suspend-account/${userId}`, null, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  } catch (error) {
    throw error.response?.data || 'An error occurred';
  }
};

export const takeDecisionRequestBecomeAmbassador = async (authToken, requestBecomeAmbassadorId, userId,  isAccepted) => {
  try {
    return await apiService.patch(`/admin/request/become-ambassador/decision`, {
      isAccepted: isAccepted,
      requestBecomeAmbassador: requestBecomeAmbassadorId,
      user: userId
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  } catch (error) {
    throw error.response?.data || 'An error occurred';
  }
};

export const takeDecisionRequestBecomeCreator = async (authToken, requestBecomeCreatorId, userId,  isAccepted) => {
  try {
   return await apiService.patch(`/admin/request/become-creator/decision`, {
      isAccepted: isAccepted,
      requestBecomeCreator: requestBecomeCreatorId,
      user: userId
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  } catch (error) {
    throw error.response?.data || 'An error occurred';
  }
};

export const unsuspendUser = async (authToken, userId) => {
  try {
    await apiService.delete(`/admin/suspend-account/${userId}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  } catch (error) {
    throw error.response?.data || 'An error occurred';
  }
};

export const hideUser = async (authToken, userId) => {
  try {
    await apiService.patch(`/admin/hide-account/${userId}`, null, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  } catch (error) {
    throw error.response?.data || 'An error occurred';
  }
};
export const hideUserFromHomepage = async (authToken, userId) => {
  try {
    const response = await apiService.patch(`/admin/toggle-hide-account-homepage/${userId}`, null, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response;
  } catch (error) {
    throw error.response?.data || 'An error occurred';
  }
};

export const unhideUser = async (authToken, userId) => {
  try {
    await apiService.delete(`/admin/hide-account/${userId}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  } catch (error) {
    throw error.response?.data || 'An error occurred';
  }
};