import React from 'react';
import { Routes, Route } from 'react-router-dom'; 
import Login from './pages/login';
import Dashboard from './pages/dashboard';
import ManageCategories from './pages/manageCategories';
import ManageRequestBecomeCreators from "./pages/manageRequestBecomeCreators";
import ManageRequestBecomeAmbassadors from "./pages/manageRequestBecomeAmbassadors";
import Creators from "./pages/creators";
import SuggestedCreators from "./pages/suggestedCreators";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/creators" element={<Creators />} />
      <Route path="/manage-categories" element={<ManageCategories />} />
      <Route path="/manage-request-become-creators" element={<ManageRequestBecomeCreators />} />
      <Route path="/manage-request-become-ambassadors" element={<ManageRequestBecomeAmbassadors />} />
      <Route path="/suggested-creators" element={<SuggestedCreators />} />

    </Routes>
  );
}

export default App;
